.section-company {
  position: relative;

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    @media (min-width: 768px) {
      width: 65%;
    }
    @media (min-width: 992px) {
      width: 42%;
    }
  }
}