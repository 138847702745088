.main-page {
  min-height: 100vh;
}

.main-cover {
  position: relative;
  padding: 190px 0 60px;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 3;
  }
}

@keyframes scale-top {
  0% {
    transform: scale(1.2);
    transform-origin: 50% 16%;
  }
  100% {
    transform: scale(1);
    transform-origin: top;
  }
}

.cover-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center top;
  z-index: 1;
  animation: scale-top 2s ease-in-out both;
}

.cover-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #191919 1.66%, rgba(25, 25, 25, 0) 30.57%),
  linear-gradient(0deg, #191919 0%, rgba(25, 25, 25, 0) 83.5%);
  z-index: 2;
}

.main-services {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1740px;
  width: 100%;
  position: relative;
  z-index: 5;
}

.main-contacts {
  padding: 30px 0;

  a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      color: $orange-color;
      text-decoration: none;
    }
  }
}

.service-card {
  background: #1F1F1F;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.35);
  padding: 50px 40px;
  display: block;
  color: #ffffff;
  text-decoration: none;
  height: 100%;
  transition: .3s ease-in-out;

  .icon-circle-arrow {
    fill: #ffffff;
    transition: .3s ease-in-out;
  }

  &_with-bg {
    padding: 2rem 2rem 2rem 2rem;
    min-height: 410px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: all .8s ease-in-out;
    @media (min-width: 576px) {
      min-height: 440px;
    }
    @media (min-width: 1200px) {
      padding: 2.5rem 4.38rem 2.5rem 3.13rem;
    }

    &:hover {
      color: #ffffff;
      text-decoration: none;
      background-size: 110% 110%;

      .service-card__content {
        top: 0;
        transform: translateY(0);
      }

      .service-card__btn {
        height: 50px;
        opacity: 1;
        color: $orange-color;
      }
    }
  }

  &__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s ease-in-out;
  }

  &__number {
    font-weight: 700;
    font-size: 4.36rem;
    line-height: 1;
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }

  &__btn {
    height: 0;
    opacity: 0;
    transition: .3s ease-in-out;
    overflow: hidden;
    color: $orange-color;

    .icon-circle-arrow {
      fill: $orange-color;
    }
  }

  &.service-card_on-main:hover {
    color: $orange-color;
    text-decoration: none;
    transform: translateY(-30px);

    .icon-circle-arrow {
      transform: rotate(-90deg);
      fill: $orange-color;
    }
  }
  &.service-card_on-main-fix:hover {
    color: #ffffff;
  }
}

.title-link {
  color: #ffffff;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}