header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0));
    opacity: 50%;
  }
}

.header-soc {
  &__icon {
    fill: #ffffff;
    transition: 0.3s ease-in-out;
  }

  &:hover .header-soc__icon {
    fill: $orange-color;
  }
}

.hamburger {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  overflow: visible;
  display: flex;
  align-items: center;

  &:hover .hamburger__box {
    border: 1px solid #ffffff;
  }
}

.hamburger__box {
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.hamburger__inner,
.hamburger__inner::before,
.hamburger__inner::after {
  width: 18px;
  height: 1px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
}

.hamburger__inner {
  left: 50%;
  top: 50%;
  margin-top: -.5px;
  transform: translateX(-50%) translateY(-50%);

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:before {
    top: -5px;
  }

  &:after {
    bottom: -5px;
  }
}

.menu-toggle {
  .hamburger__inner {
    background-color: transparent;
  }

  .hamburger__inner::before {
    top: 0;
    transform: rotate(45deg);
    transform-origin: center;
  }

  .hamburger__inner::after {
    top: 0;
    transform: rotate(-45deg);
    transform-origin: center;
  }
}

.menu-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #191919;
  z-index: 10;
  padding: 90px 0;
  visibility: hidden;
  opacity: 0;
  transition: .3s ease-in-out;
  transform: scale(1.1);
  @media (min-width: 1200px) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-color: #1F1F1F;
      z-index: -1;
    }
  }

  &_show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

.nav-menu {
  @media (min-width: 992px) {
    padding-left: 56px;
  }

  &__link {
    display: block;
    text-decoration: none;
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 24px;
    position: relative;
    @media (min-width: 576px) {
      font-size: 1.3rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.4rem;
    }

    &:before {
      content: "";
      position: absolute;
      right: calc(100% + 32px);
      top: 50%;
      width: 40px;
      height: 1px;
      background: linear-gradient(to right, transparentize($orange-color, 1), $orange-color);
      opacity: 0;
      visibility: hidden;
      transition: .3s ease-in-out;
    }

    &.is-active {
      color: $orange-color;

      &:before {
        opacity: 1;
        visibility: visible;
        right: calc(100% + 16px);
      }
    }

    &:hover {
      text-decoration: none;

      &:before {
        opacity: 1;
        visibility: visible;
        right: calc(100% + 16px);
      }
    }
  }
}