html {
  font-size: 14px;
  overflow-x: hidden;
  @media (min-width: 1400px) {
    font-size: 16px;
  }
}

body {
  color: #ffffff;
  background-color: #191919;
  font-family: 'Manrope', sans-serif;
  line-height: 1.6;
  letter-spacing: 1px;
  font-weight: 300;

  &.modal-open {
    overflow: hidden;
  }
}

a {
  color: #ffffff;
  text-decoration: underline;
  transition: color .3s ease-in-out;

  &:hover {
    color: $orange-color;
    text-decoration: underline;
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;
  color: $orange-color;
  text-decoration: none;
  background: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.3536 25.3536C38.5488 25.1583 38.5488 24.8417 38.3536 24.6464L35.1716 21.4645C34.9763 21.2692 34.6597 21.2692 34.4645 21.4645C34.2692 21.6597 34.2692 21.9763 34.4645 22.1716L37.2929 25L34.4645 27.8284C34.2692 28.0237 34.2692 28.3403 34.4645 28.5355C34.6597 28.7308 34.9763 28.7308 35.1716 28.5355L38.3536 25.3536ZM12 25.5H38V24.5H12V25.5Z' fill='%23FF9933'/%3E%3Ccircle cx='25' cy='25' r='24.5' stroke='%23FF9933'/%3E%3C/svg%3E%0A") no-repeat 100% center;
  min-height: 50px;
  padding-right: 66px;
  transition: .3s ease-in-out;

  &:hover {
    text-decoration: none;
    transform: translateX(16px);
  }
}

article {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
  }
}

b, strong {
  font-weight: 700;
}

ul {
  list-style: none;
  padding-left: 30px;

  li {
    position: relative;
    margin-bottom: 8px;

    &:before {
      content: "";
      position: absolute;
      left: -30px;
      top: 12px;
      width: 20px;
      height: 1px;
      background: linear-gradient(to right, transparentize($orange-color, 1), $orange-color);
    }
  }
}

h1, .h1 {
  line-height: 1.2;
  font-weight: 500;
  @media (min-width: 1200px) {
    font-size: 3.13rem;
    font-weight: 500;
  }
}

h2, .h2 {
  line-height: 1.3;
  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.2rem + 0.3vw);
  line-height: 1.44;
  font-weight: 500;
  @media (min-width: 1200px) {
    font-size: 1.60rem;
  }
}

h4, .h4 {
  line-height: 1.5;
  font-weight: 500;
  font-size: calc(1rem + 0.3vw);
  @media (min-width: 1200px) {
    font-size: 1.3rem;
  }
}

.solid-line {
  max-width: 90px;
  background-color: $orange-color;
  opacity: 1;
}

.gradient-line {
  max-width: 90px;
  background: linear-gradient(to right, transparentize($orange-color, 1), $orange-color);
  opacity: 1;
}

.big-line {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0));
  opacity: 50%;
}

.inner-page {
  padding-top: 120px;
  @media (min-width: 768px) {
    padding-top: 190px;
  }
}

.mb-120 {
  margin-bottom: 60px;
  @media (min-width: 768px) {
    margin-bottom: 120px;
  }
}

.dark-img {
  position: relative;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.35);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    background: linear-gradient(72.02deg, rgba(31, 31, 31, 0.9) 0%, rgba(31, 31, 31, 0) 52.6%, rgba(31, 31, 31, 0.9) 100%);
  }
}

.c-scrollbar_thumb {
  background-color: $orange-color;
}