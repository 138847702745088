.service-detail-img-wrapper {
  overflow: hidden;
  position: relative;
  height: 25vh;
  @media (min-width: 992px) {
    height: 50vh;
    min-height: 550px;
  }
}

.service-detail-img {
  left: 0;
  position: absolute;
  right: 0;
  background-position: 50%;
  background-size: cover;
  top: 0;
  height: 100%;
  @media (min-width: 1200px) {
    top: -100vh;
    height: 100vh;
  }
}

#fixed-target {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  @media (min-width: 1200px) {
    top: -100vh;
    bottom: -100vh;
  }
}

.accordion-button {
  display: block;
  position: relative;
  background: transparent;
  padding: 0 46px 8px 0;
  color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: left;
  transition: .3s ease-in-out;

  &__icon {
    content: "";
    position: absolute;
    top: 0;
    right: 0;

    use {
      fill: #ffffff;
      transition: .3s ease-in-out;
      transform-origin: center center;
    }
  }

  &:hover {
    color: $orange-color;
    border-color: $orange-color;

    .accordion-button__icon use {
      fill: $orange-color;
    }
  }

  &[aria-expanded=true] {
    color: $orange-color;
    border-color: $orange-color;

    .accordion-button__icon use {
      fill: $orange-color;
      transform: rotate(45deg);
    }
  }
}

.accordion-body {
  padding: 24px 30px 1px;
}