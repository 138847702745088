.block-title {
  @media (min-width: 768px) {
    position: absolute;
    z-index: 1;
    width: 60%;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (min-width: 992px) {
    width: 50%;
  }
}